import { point as pointUrl } from '#/js/config/api.json';

export default {
    getPrizeList(params = {offset = 0, pageSize = 0} = {}) {
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'point',
            baseURLPort: 8702,
            url: pointUrl.prize_list,
            data: params
        });
    },
    getPrizeTypes() {
        return _request({
            method: 'GET',
            baseURL: 'LbdJavaApi',
            javaProject: 'point',
            baseURLPort: 8702,
            url: pointUrl.prize_type_list,
        });
    },
    /**
     * 
     * @param {
        * image: String 图片url
        * isShelves: Boolbean 是否上架
        * name: String 奖品名称
        * point: Number/String 需要积分
        * prizeId: String 对应奖品
     * }
     */
    addPrize(params = {image = "", isShelves = false, name = "", point = 0, prizeId = ""} = {}) {
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'point',
            baseURLPort: 8702,
            url: pointUrl.prize_setting_add,
            data: params
        });
    },
    /**
     * 
     * @param {
        * id: String 奖品管理Id
        * image: String 图片url
        * isShelves: Boolbean 是否上架
        * name: String 奖品名称
        * point: Number/String 需要积分
        * prizeId: String 对应奖品
     * }
     */
    editPrize(params = {id = "", image = "", isShelves = false, name = "", point = 0, prizeId = ""} = {}) {
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'point',
            baseURLPort: 8702,
            url: pointUrl.prize_setting_edit,
            data: params
        });
    },
    deletePrize(params = {id = ""} = {}) {
        return _request({
            method: 'DELETE',
            baseURL: 'LbdJavaApi',
            javaProject: 'point',
            baseURLPort: 8702,
            url: pointUrl.prize_setting_delete,
            data: params
        });
    },
    updateImage(formData) {
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'point',
            baseURLPort: 8702,
            url: pointUrl.prize_image_upload,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        });
    },
    getSettleList(params = {startTime = "", endTime = "", offset = 0, pageSize = 0, userIds = []} = {}) {
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'point',
            baseURLPort: 8702,
            url: pointUrl.settle_list,
            data: params
        });
    },
    grantPrize(params = {id = ""} = {}) {
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'point',
            baseURLPort: 8702,
            url: pointUrl.settle_prize_grant,
            data: params
        });
    },
    exportSettleList(params = {startTime = "", endTime = "", offset = 0, pageSize = 0, userIds = []} = {}) {
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'point',
            baseURLPort: 8702,
            url: pointUrl.settle_list_export,
            data: params
        });
    },
    getPointLlist(params = {start = 0, take = 0, userIds = []} = {}) {
        return _request({
            method: 'POST',
            url: pointUrl.point_list,
            data: params
        });
    },
    alterPoint(params = {userIds = [], point = 0, remarks = ""} = {}) {
        return _request({
            method: "POST",
            url: pointUrl.alter_user_point,
            data: params
        })
    }
}