<template>
  <div class="prize-table">
    <div class="prize-table-top">
      <span class="title">奖品管理</span>
      <el-button class="prize-btn" @click="showPrizeDialog" type="primary">添加奖品</el-button>
    </div>
    <div class="prize-table-body">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        height="800"
        tooltip-effect="light"
      >
        <el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
        <el-table-column align="center" label="奖品图片">
          <template slot-scope="scope">
            <div class="prize-image">
              <img v-if="scope.row.image" :src="scope.row.image" :alt="scope.row.name" />
              <img v-else src="~@src/assets/images/pic_gift.png" :alt="scope.row.name" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" :show-overflow-tooltip="true" label="奖品名称"></el-table-column>
        <el-table-column prop="description" align="center" label="对应奖品" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="point" align="center" label="需要积分" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div
              :class="scope.row.isShelves ? 'putaway-status' : 'soldout-status'"
            >{{scope.row.isShelves ? '已上架' : '已下架'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="260">
          <template slot-scope="scope">
            <span
              class="operation-column-btn text-operate-btn"
              @click="showPrizeDialog(scope.row)"
            >编辑</span>
            <i>|</i>
            <span
              v-if="!scope.row.isShelves"
              class="operation-column-btn text-operate-btn"
              @click="handlePutaway(scope.row)"
            >上架</span>
            <span
              v-else
              class="operation-column-btn text-operate-btn"
              @click="handleSoldOut(scope.row)"
            >下架</span>
            <i>|</i>
            <span
              class="operation-column-btn text-operate-btn red"
              @click="handleDelete(scope.row.name, scope.row.id)"
            >删除</span>
          </template>
        </el-table-column>
        <template slot="empty">
          <div>
            <span class="empty-img"></span>
            <p class="empty-data">暂无数据</p>
          </div>
        </template>
      </el-table>
    </div>
    <prize-dialog ref="prizeDialog" @refresh-list="search"></prize-dialog>
  </div>
</template>

<script>
import PrizeDialog from "./prize-dialog.vue";
import PointSettingService from '#/js/service/pointSettingService.js';
export default {
  props: {
    tableData: Array
  },
  components: {
    PrizeDialog
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    search() {
      this.$emit("search", {});
    },
    showPrizeDialog(json) {
      if (json) {
        this.$refs.prizeDialog.show(json);
      } else {
        this.$refs.prizeDialog.show();
      }
    },
    handlePutaway(prize) {
      this.$confirm(`确定上架商品 ${prize.name} ？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false
      }).then(() => {
        this.loading = true;
        let params = {
          id: prize.id,
          image: prize.image,
          isShelves: true,
          name: prize.name,
          point: prize.point,
          prizeId: prize.prizeId
        };
        PointSettingService.editPrize(params)
        .then(res => {
          this.loading = false;
          shortTips(`商品 ${prize.name} 已上架`);
          prize.isShelves = true;
          this.$parent.tableData.sort((item1, item2) => {
            return item2.isShelves - item1.isShelves;
          });
        }).catch(err => {
          this.loading = false;
          console.log(err);
        })
      }).catch(() => {});
    },
    handleSoldOut(prize) {
      this.$confirm(`确定下架商品 ${prize.name}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false
      }).then(() => {
        this.loading = true;
        let params = {
          id: prize.id,
          image: prize.image,
          isShelves: false,
          name: prize.name,
          point: prize.point,
          prizeId: prize.prizeId
        };
        PointSettingService.editPrize(params)
        .then(res => {
          this.loading = false;
          shortTips(`商品 ${prize.name} 已下架`);
          prize.isShelves = false;
          this.search();
        }).catch(err => {
          this.loading = false;
          console.log(err);
        })
      }).catch(() => {});
    },
    handleDelete(name, id) {
      this.$confirm(`确定删除商品 ${name}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false
      }).then(() => {
        this.loading = true;
        PointSettingService.deletePrize({id: id})
        .then(res => {
          this.loading = false;
          shortTips(`商品 ${name} 已删除`);
          this.search();
        }).catch(err => {
          this.loading = false;
          console.log(err);
        })
      }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" scope>
.prize-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-top {
    margin: 12px 0;
    padding-left: 32px;
    height: 28px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 18px;
      color: #4a4a4a;
    }
  }
  &-body {
    flex-grow: 1;
    height: calc(100% - 52px);
    letter-spacing: 0px;
    .operation-column-btn {
      & + i {
        margin: 0 4px;
        color: #ddd;
      }
    }

    .el-table {
      background-color: transparent;
      height: 100% !important;
      overflow-y: auto;
      min-height: 244px;
      .el-table__body-wrapper {
        height: calc(100% - 43px) !important;
        background-color: #fff;
        overflow-y: auto;
      }
      th {
        padding: 0;
        height: 42px;
        line-height: 42px;
        background-color: #e8e8e8;
        border-color: #ccc;
        color: #666;
      }
      td {
        height: 120px;
        padding: 20px 0;
      }
      .prize-image {
        width: 80px;
        height: 80px;
        display: inline-block;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .putaway-status {
        color: $primary;
      }
      .soldout-status {
        color: #999;
      }
      .el-table__empty-block {
        .el-table__empty-text {
          line-height: inherit;
        }
        .empty-img {
          display: inline-block;
          background: url("~@src/assets/images/home/icon_no_data.svg") no-repeat;
          width: 240px;
          height: 228px;
        }
        .empty-data {
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>