<template>
    <el-dialog
        class="prize-dialog"
        :title="isEdit ? '编辑奖品' : '添加奖品'"
        :visible.sync="visible"
        width="480px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="handleClose"
        v-loading="loading"
    >
        <el-form :model="form" :rules="rules" ref="prizeForm" label-width="90px">
        <el-form-item label="奖品名称" prop="name">
            <el-input v-model="form.name" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="奖品图片" prop="image">
            <el-upload
                ref="uploader"
                class="image-upload"
                action="http://192.168.1.191:8702/upload/file"
                :on-change="handleChange"
                :on-remove="handleRemove"
                :http-request="handleImageUpload"
                :file-list="fileList"
                :multiple="false"
                :auto-upload="false"
                :show-file-list="false"
                accept=".jpg,.png"
            >
                <el-button slot="trigger" size="small" type="primary">选取图片</el-button>
                <span class="file-list" v-if="fileList.length > 0">
                    <i class="el-icon-picture-outline"></i>
                    <span class="file-name">{{fileList[0].name}}</span>
                    <i class="el-icon-close" @click="deleteFile"></i>
                </span>
            </el-upload>
        </el-form-item>
        <el-form-item class="is-required" :class="prizeSelectError ? 'is-error' : ''" label="对应奖品" prop="prizeId">
            <el-select
                v-model="form.prizeId"
                placeholder="请选择"
                @blur="prizeBlur"
                @change="prizeChange"
            >
                <el-option
                    v-for="(prize, index) in prizeType"
                    :key="index"
                    :label="prize.description"
                    :value="prize.id"
                ></el-option>
            </el-select>
            <span class="error-text" v-if="prizeSelectError">请选择对应奖品</span>
        </el-form-item>
        <el-form-item label="需要积分" prop="point">
            <el-input v-model="form.point" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="是否上架" prop="isShelves" class="prize-dialog-checkbox">
            <el-checkbox v-model="form.isShelves">上架</el-checkbox>
        </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import PointSettingService from '#/js/service/pointSettingService.js';
import debounce from "lodash/debounce";
let _this = {};
export default {
    name: "PrizeDialog",
    data() {
        return {
            visible: false,
            loading: false,
            isEdit: false,
            form: {
                name: "",
                image: "",
                prizeId: "",
                point: "",
                isShelves: false
            },
            rules: {
                name: [
                    { required: true, message: "请输入奖品名称", trigger: "blur" },
                    { max: 10, message: "长度不能超过10个字", trigger: "blur" }
                ],
                point: [{
                    required: true,
                    trigger: "blur",
                    validator: (rule, value, callback) => {
                        if(value === "") {
                            callback(new Error("请输入需要积分"));
                        } else if(!(/^\d+$/g.test(value) && parseInt(value) > 0 && parseInt(value) < 10000)) {
                            callback(new Error("积分的范围是1~9999的整数"));
                        } else {
                            callback();
                        }
                    }
                }]
            },
            prizeType: [],
            fileList: [],
            prizeSelectError: false
        };
    },
    created() {
        _this = this;
    },
    mounted() {
    },
    methods: {
        show(json) {
            this.visible = true;
            if(json.id) {
                this.isEdit = true;
                Object.assign(this.form, json);
            }
            this.getPrizeType();
        },
        getPrizeType() {
            PointSettingService.getPrizeTypes()
            .then(res => {
                if(this.isEdit) {
                    let typeIndex = res.findIndex(item => {
                        return item.id == this.form.prizeId;
                    });
                    if(typeIndex < 0) {
                        res.unshift({
                            id: this.form.prizeId,
                            description: this.form.description
                        });
                    }
                }
                this.prizeType = res;
            }).catch(err => {
                console.log(err);
            })
        },
        handleClose() {
            this.visible = false;
            this.isEdit = false;
            this.fileList = [];
            this.form = {
                name: "",
                image: "",
                prizeId: "",
                point: "",
                isShelves: false
            }
            this.$refs.prizeForm.resetFields();
            this.prizeSelectError = false;
        },
        handleConfirm() {
            this.$refs.prizeForm.validate(valid => {
                if(!this.form.prizeId) {
                    this.prizeSelectError = true;
                } else {
                    this.prizeSelectError = false;
                }
                if(valid) {
                    if(this.prizeSelectError) {
                        return false;
                    }
                    if(this.fileList.length > 0) {
                        this.$refs.uploader.submit();
                    } else {
                        this.submitForm();
                    }
                }else {
                    return false;
                }
            });
        },
        submitForm() {
            let data = Object.assign({}, this.form);
            this.loading = true;
            if(this.isEdit) {
                PointSettingService.editPrize(data)
                .then(res => {
                    this.loading = false;
                    shortTips("编辑成功");
                    this.handleClose();
                    this.$emit("refresh-list");
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                })
            } else {
                PointSettingService.addPrize(data)
                .then(res => {
                    this.loading = false;
                    shortTips("添加成功");
                    this.handleClose();
                    this.$emit("refresh-list");
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                })
            }

        },
        handleChange: debounce((file, fileList) => {
            let fileName = file.name.slice(0, file.name.lastIndexOf("."));
            if(fileName.length > 50) {
                _this.fileList = [];
                shortTips("文件名长度不能超过50！");
                return;
            }
            if(file.size / 1024 / 1024 > 1) {
                _this.fileList = [];
                shortTips("文件大小不得超过1M");
                return;
            }
            _this.fileList = [];
            _this.fileList.push(file);
        }, 300),
        handleRemove(file, fileList) {
            this.fileList = fileList;
        },
        handleImageUpload() {
            let formData = new FormData();
            this.fileList.map((el, index) => {
                if (el.raw) {
                    formData.append(`file`, el.raw);
                    formData.append(`type`, '0');
                }
            });
            this.loading = true;
            PointSettingService.updateImage(formData)
            .then(res => {
                if(res) {
                    this.form.image = res;
                } else {
                    shortTips("图片上传失败");
                }
                this.submitForm();
            }).catch(err => {
                this.loading = false;
                console.log(err);
            })
        },
        prizeBlur(ev) {
            if(!ev.srcElement.value) {
                this.prizeSelectError = true;
            } else {
                this.prizeSelectError = false;
            }
        },
        deleteFile() {
            this.fileList = [];
        },
        prizeChange(value) {
            if(value) {
                this.prizeSelectError = false;
            } else {
                this.prizeSelectError = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.prize-dialog {
  .el-select {
    width: 100%;
  }
  .error-text {
    color: #F56C6C;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
  }
  .el-form-item__label {
    padding-right: 20px;
  }
  .image-upload {
    display: flex;
    // .el-upload-list {
    //   display: inline-block;
    //   .el-upload-list__item {
    //     margin-top: 0;
    //     line-height: 40px;
    //     &:hover {
    //       .el-icon-close {
    //         top: 13px;
    //       }
    //     }
    //   }
    // }
    .file-list {
        display: inline-block;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        margin-left: 5px;
        margin-top: 6px;
        padding: 0 25px;
        position: relative;
        min-width: 128px;
        .el-icon-picture-outline {
            position: absolute;
            top: 7px;
            left: 5px;
        }
        .file-name {
            display: inline-block;
            max-width: 210px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .el-icon-close {
            display: none;
            cursor: pointer;
            opacity: .75;
            color: #606266;
            position: absolute;
            top: 7px;
            right: 5px;
            margin-left: 20px;
            &:hover {
                opacity: 1;
            }
        }
        &:hover {
            background-color: #F5F7FA;
            .el-icon-close {
                display: inline-block;
            }
        }
    }
  }
  &-checkbox {
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #fff;

      &:after {
        border-color: $primary;
      }
    }
  }
}
</style>