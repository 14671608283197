var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "prize-dialog",
      attrs: {
        title: _vm.isEdit ? "编辑奖品" : "添加奖品",
        visible: _vm.visible,
        width: "480px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "prizeForm",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "90px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "奖品名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请填写" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "奖品图片", prop: "image" } },
            [
              _c(
                "el-upload",
                {
                  ref: "uploader",
                  staticClass: "image-upload",
                  attrs: {
                    action: "http://192.168.1.191:8702/upload/file",
                    "on-change": _vm.handleChange,
                    "on-remove": _vm.handleRemove,
                    "http-request": _vm.handleImageUpload,
                    "file-list": _vm.fileList,
                    multiple: false,
                    "auto-upload": false,
                    "show-file-list": false,
                    accept: ".jpg,.png",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "trigger",
                        size: "small",
                        type: "primary",
                      },
                      slot: "trigger",
                    },
                    [_vm._v("选取图片")]
                  ),
                  _vm.fileList.length > 0
                    ? _c("span", { staticClass: "file-list" }, [
                        _c("i", { staticClass: "el-icon-picture-outline" }),
                        _c("span", { staticClass: "file-name" }, [
                          _vm._v(_vm._s(_vm.fileList[0].name)),
                        ]),
                        _c("i", {
                          staticClass: "el-icon-close",
                          on: { click: _vm.deleteFile },
                        }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "is-required",
              class: _vm.prizeSelectError ? "is-error" : "",
              attrs: { label: "对应奖品", prop: "prizeId" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { blur: _vm.prizeBlur, change: _vm.prizeChange },
                  model: {
                    value: _vm.form.prizeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "prizeId", $$v)
                    },
                    expression: "form.prizeId",
                  },
                },
                _vm._l(_vm.prizeType, function (prize, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: prize.description, value: prize.id },
                  })
                }),
                1
              ),
              _vm.prizeSelectError
                ? _c("span", { staticClass: "error-text" }, [
                    _vm._v("请选择对应奖品"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "需要积分", prop: "point" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.point,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "point", $$v)
                  },
                  expression: "form.point",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "prize-dialog-checkbox",
              attrs: { label: "是否上架", prop: "isShelves" },
            },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.form.isShelves,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isShelves", $$v)
                    },
                    expression: "form.isShelves",
                  },
                },
                [_vm._v("上架")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("取消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }