<template>
    <div class="settlement-table">
        <div class="settlement-table-top">
            <span class="title">结算管理</span>
            <div class="settlement-table-operation">
                <div class="date-wrap triangle-drop" :class="isFocusDate?'triangle-drop-up':'triangle-drop-down'">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        start-placeholder="开始日期"
                        range-separator="-"
                        end-placeholder="结束日期"
                        size="small"
                        :editable="false"
                        align="right"
                        format="yyyyMMdd"
                        @change="handleDate"
                        @focus="handleFocusDate"
                        @blur="handleBlurDate"
                        :clearable="false"
                        :picker-options="expireTimeOption"
                        popper-class="performance-data-range"
                    ></el-date-picker>
                </div>
                <div class="employee-select-wrap triangle-drop" :class="teamMemberPopoverVisible?'triangle-drop-up':'triangle-drop-down'">
                    <el-popover
                        popper-class="team-member-popover settlement-team-member-popover"
                        placement="bottom-end"
                        width="700"
                        trigger="click"
                        v-model="teamMemberPopoverVisible"
                        >
                        <team-member-select
                            ref="teamSelect"
                            :isAllMembers="true"
                            :isShowDimission="false"
                            :date="dateRange"
                            :isHistoryData="true"
                            :visible="teamMemberPopoverVisible"
                            :selected="params.userIds"
                            @item-select-cb="employeeSelect"
                        ></team-member-select>
                        <div slot="reference" class="employee-select-text">{{selectedMembersText}}</div>
                    </el-popover>
                </div>
                <el-select
                    class="status-select"
                    v-model="params.status"
                    @change="search"
                >
                    <span slot="prefix" class="status-icon">
                        <font-icon href="#icon-menu"></font-icon>
                    </span>
                    <el-option label="全部状态" value=""></el-option>
                    <el-option label="已发放" :value="true"></el-option>
                    <el-option label="未发放" :value="false"></el-option>
                </el-select>
                <el-button class="import-detail" @click="importDetail" type="primary">导出明细</el-button>
            </div>
        </div>
        <div class="settlement-table-body">
            <el-table
                v-loading="loading"
                :data="tableData"
                border
                style="width: 100%"
                height="800"
                tooltip-effect="light"
            >
                <el-table-column
                    prop="userName"
                    align="left"
                    label="成员姓名"
                    min-width="220"
                    :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                    prop="prizeName"
                    align="center"
                    label="兑换奖品"
                    width="150"
                    :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                    prop="points"
                    align="center"
                    label="消耗积分"
                    width="130"
                ></el-table-column>
                <el-table-column
                    prop="created"
                    align="center"
                    label="申请兑换时间"
                    min-width="220"
                >
                    <template slot-scope="scope">
                        <div class="settlement-time">{{scope.row.created | timeFilter}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="confirmPayTime"
                    align="center"
                    label="确认发放时间"
                    min-width="220"
                >
                    <template slot-scope="scope">
                        <div class="settlement-time" v-if="scope.row.status">{{scope.row.confirmPayTime | timeFilter}}</div>
                        <div class="settlement-time" v-else>-</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="状态"
                    align="center"
                    width="130"
                >
                    <template slot-scope="scope">
                        <div :class="scope.row.status ? 'given-status' : 'has-not-given'">{{scope.row.status ? '已发放' : '未发放'}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    align="center"
                    min-width="140"
                >
                    <template slot-scope="scope">
                        <span v-if="!scope.row.status" class="text-operate-btn" @click="giveOut(scope.row)">发放</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <template slot="empty">
                    <div>
                        <span class="empty-img"></span>
                        <p class="empty-data">暂无数据</p>
                    </div>
                </template>
            </el-table>
        </div>
    </div>
</template>

<script>
import TeamMemberSelect from '#/component/common/team-member-select.vue';
import PointSettingService from '#/js/service/pointSettingService.js';
import moment from 'moment';
export default {
    props: {
        tableData: Array
    },
    components: {
        TeamMemberSelect
    },
    data() {
        return {
            loading: false,
            teamMemberPopoverVisible: false,
            selectedMembersText: '全部成员',
            params: {
                userIds: [],
                startTime: '',
                endTime: '',
                status: ''
            },
            dateRange: [],
            isFocusDate: false,
            expireTimeOption: {
                disabledDate(date) {
                    return date.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '昨天',
                    onClick(picker) {
                        const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '今天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近7天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近30天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本月',
                    onClick(picker) {
                        const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                        const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '上月',
                    onClick(picker) {
                        const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                        const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }]
            }
        }
    },
    filters: {
        timeFilter(val) {
            return moment(val).format('YYYY.MM.DD HH:mm:DD');
        }
    },
    mounted() {
        this.dateRange = [moment().subtract(29, 'days').format('YYYY-MM-DD'), moment().subtract(0, 'days').format('YYYY-MM-DD')];
        this.params = Object.assign({}, this.params, {
           startTime: this.dateRange[0],
           endTime: this.dateRange[1]
        });
        this.loading = true;
        this.$refs.teamSelect.getHistoryTeamData(true);
    },
    methods: {
        search() {
            this.$emit('search', this.params);
        },
        // 成员选取
        employeeSelect(userIds, text) {
            this.params.userIds = userIds;
            this.selectedMembersText = text;
            this.search();
            this.teamMemberPopoverVisible = false;
        },
        handleFocusDate(){
            this.isFocusDate = true;
        },
        handleBlurDate(){
            this.isFocusDate = false;
        },
        handleDate(value){
            this.params.startTime = moment(value[0]).format('YYYY-MM-DD');
            this.params.endTime = moment(value[1]).format('YYYY-MM-DD');
            this.memberTableLoading = true;
            this.$refs.teamSelect.getHistoryTeamData(true);
        },
        importDetail() {
            this.loading = true;
            let params = Object.assign({}, this.params);
            let tempWindow = window.open('', '_self');
            params.offset = (this.$parent.page.current - 1) * this.$parent.page.size;
            params.pageSize = this.$parent.page.size;
            PointSettingService.exportSettleList(params)
            .then(res => {
                this.loading = false;
                tempWindow.location = res;
                shortTips("导出成功，请查收");
            }).catch(err => {
                this.loading = false;
                console.log(err);
            })
        },
        giveOut(detail) {
            this.$confirm(`确定将奖品 ${detail.prizeName} 发放给用户 ${detail.userName}？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
            }).then(() => {
                this.loading = true;
                PointSettingService.grantPrize({id: detail.id})
                .then(res => {
                    this.loading = false;
                    shortTips(`奖品 ${detail.prizeName} 发放成功`);
                    detail.status = true;
                    detail.confirmPayTime = new Date();
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                })
            }).catch(() => {});
        }
    }
};
</script>

<style lang="scss" scope>
    .settlement-table {
        display: flex;
        flex-direction: column;
        height: 100%;
        &-top {
            margin: 12px 0;
            padding-left: 32px;
            height: 28px;
            line-height: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
                font-size: 18px;
                color: #4a4a4a;
            }
            .settlement-table-operation {
                display: flex;
                .date-wrap,
                .employee-select-wrap,
                .status-select {
                    margin-right: 12px;
                }
                .date-wrap {
                    position: relative;
                    cursor: pointer;
                    height: 28px;
                    .el-range-separator{
                        padding: 0;
                        margin: 0 3px;
                        line-height: 20px;
                    }
                    &:hover {
                        .el-date-editor--daterange.el-input__inner{
                            border-color:#38BC9D;
                            background: #38BC9D;
                            .el-range-input{
                                background: #38BC9D;
                                color: #fff;
                            }
                            .el-range-separator{
                                color: #fff;
                            }
                        }
                    }
                    .el-range-editor {
                        &.is-active {
                            background: #38BC9D;
                            .el-range-input{
                                background: #38BC9D;
                                color: #fff;
                            }
                            .el-range-separator{
                                color: #fff;
                            }
                        }
                    }
                    .el-date-editor--daterange.el-input__inner {
                        width: 190px;
                        border-radius: 16px;
                        height: 28px;
                    }
                    .el-date-editor{
                        .el-range-input {
                            color: #666;
                        }
                        .el-range__icon {
                            display: none;
                        }
                        .el-range__close-icon {
                            display: none;
                        }
                    } 
                }
                .employee-select-wrap {
                    position: relative;

                    .employee-select-text {
                        display: inline-block;
                        width: 116px;
                        height: 28px;
                        line-height: 28px;
                        background-color: #fff;
                        border: 1px solid #d3d3d3;
                        border-radius: 16px;
                        padding: 0 36px 0 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: top;
                    }

                    &:hover{
                        .employee-select-text{
                            background: #38BC9D;
                            border-color: #38BC9D;
                            color: #fff;
                        }

                    }
                    &.triangle-drop-up{
                        &::after{
                            border-bottom: 12px solid #fff;
                            margin-top: -3px;
                        }
                        .employee-select-text{
                            background: #38BC9D;
                            border-color: #38BC9D;
                            color: #fff;
                        }
                    }
                }
                .triangle-drop {
                    &::after {
                        position: absolute;
                        display: block;
                        content: '';
                        top: 50%;
                        transform: translateY(-50%);
                        right: 16px;
                        pointer-events: none;
                    }
                    &-down {
                        &::after {
                            border-top: 12px solid #ddd;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                        }
                        &:hover {
                            &::after {
                                border-top: 12px solid #fff;
                            }
                        }
                    }
                    &-up {
                        .team-select {
                            background: #38BC9D;
                            border-color: #38BC9D;
                            .team-select-text{
                                color: #fff;
                            }
                        }
                        &::after {
                            border-bottom: 12px solid #ddd;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                        }
                        &:hover {
                            &::after {
                                border-bottom: 12px solid #fff;
                            }
                        }
                    }
                }
                .status-select {
                    height: 28px;
                    width: 152px;
                    .el-input__inner {
                        height: 28px;
                        line-height: 28px;
                        color: #666;
                    }
                    .status-icon {
                        font-size: 18px;
                    }
                    .el-input__icon {
                        line-height: 28px;
                    }
                }
            }
        }
        &-body {
            flex-grow: 1;
            height: calc(100% - 52px);
            letter-spacing: 0px;
            .operation-column-btn {
                & + i {
                    margin: 0 4px;
                    color: #ddd;
                }
            }

            .el-table {
                background-color: transparent;
                height: 100% !important;
                overflow-y: auto;
                min-height: 244px;
                .el-table__body-wrapper {
                    height: calc(100% - 43px) !important;
                    background-color: #fff;
                    overflow-y: auto;
                }
                th {
                    padding: 0;
                    height: 42px;
                    line-height: 42px;
                    background-color: #E8E8E8;
                    border-color: #CCC;
                    color: #666;
                    &:nth-of-type(1) {
                        .cell {
                            padding-left: 54px;
                        }
                    }
                }
                td {
                    &:nth-of-type(1) {
                        .cell {
                            padding-left: 54px;
                        }
                    }
                }
                .given-status {
                    color: $primary;
                }
                .has-not-given {
                    color: #999;
                }
                .el-table__empty-block {
                    .el-table__empty-text {
                        line-height: inherit;
                    }
                    .empty-img {
                        display: inline-block;
                        background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                        width: 240px;
                        height: 228px;
                    }
                    .empty-data {
                        font-size: 16px;
                        line-height: 21px;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
</style>

<style lang="scss">
.team-member-popover.settlement-team-member-popover {
    transform: translateX(0);
}
.performance-data-range.el-picker-panel{
    .el-date-range-picker__content.is-left{
        border-right: none;
    }
    .el-date-table{
        .el-date-table__row{
            .normal.disabled{
                div{
                    color: #666;
                }
            }
        }
    }
}
</style>