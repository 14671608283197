var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "prize-table" },
    [
      _c(
        "div",
        { staticClass: "prize-table-top" },
        [
          _c("span", { staticClass: "title" }, [_vm._v("奖品管理")]),
          _c(
            "el-button",
            {
              staticClass: "prize-btn",
              attrs: { type: "primary" },
              on: { click: _vm.showPrizeDialog },
            },
            [_vm._v("添加奖品")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "prize-table-body" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                height: "800",
                "tooltip-effect": "light",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  align: "center",
                  label: "序号",
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "奖品图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "prize-image" }, [
                          scope.row.image
                            ? _c("img", {
                                attrs: {
                                  src: scope.row.image,
                                  alt: scope.row.name,
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@src/assets/images/pic_gift.png"),
                                  alt: scope.row.name,
                                },
                              }),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  align: "center",
                  "show-overflow-tooltip": true,
                  label: "奖品名称",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "description",
                  align: "center",
                  label: "对应奖品",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "point",
                  align: "center",
                  label: "需要积分",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: { label: "状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            class: scope.row.isShelves
                              ? "putaway-status"
                              : "soldout-status",
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.isShelves ? "已上架" : "已下架")
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "260" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass:
                              "operation-column-btn text-operate-btn",
                            on: {
                              click: function ($event) {
                                return _vm.showPrizeDialog(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("i", [_vm._v("|")]),
                        !scope.row.isShelves
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "operation-column-btn text-operate-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePutaway(scope.row)
                                  },
                                },
                              },
                              [_vm._v("上架")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass:
                                  "operation-column-btn text-operate-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSoldOut(scope.row)
                                  },
                                },
                              },
                              [_vm._v("下架")]
                            ),
                        _c("i", [_vm._v("|")]),
                        _c(
                          "span",
                          {
                            staticClass:
                              "operation-column-btn text-operate-btn red",
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.name,
                                  scope.row.id
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c("div", [
                  _c("span", { staticClass: "empty-img" }),
                  _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("prize-dialog", {
        ref: "prizeDialog",
        on: { "refresh-list": _vm.search },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }