<template>
    <div class="point-table">
        <div class="point-table-top">
            <span class="title">积分管理</span>
            <div class="point-table-operation">
                <div class="employee-select-wrap triangle-drop" :class="teamMemberPopoverVisible?'triangle-drop-up':'triangle-drop-down'">
                    <el-popover
                        popper-class="team-member-popover point-team-member-popover"
                        placement="bottom-end"
                        width="700"
                        trigger="click"
                        v-model="teamMemberPopoverVisible"
                    >
                        <team-member-select
                            ref="teamSelect"
                            :isAllMembers="true"
                            :isShowDimission="false"
                            :visible="teamMemberPopoverVisible"
                            :selected="params.userIds"
                            @item-select-cb="employeeSelect"
                        ></team-member-select>
                        <div slot="reference" class="employee-select-text">{{selectedMembersText}}</div>
                    </el-popover>
                </div>
                <!-- <el-button class="import-detail" @click="importDetail" type="primary">导出明细</el-button> -->
            </div>
        </div>
        <div class="point-table-body">
            <el-table
                ref="multipleTable"
                v-loading="loading"
                :data="tableData"
                border
                style="width: 100%"
                tooltip-effect="light"
                @selection-change="handleSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="40"
                ></el-table-column>
                <el-table-column
                    class="userName-column"
                    prop="userName"
                    align="left"
                    label="成员姓名"
                    min-width="180"
                    :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                    prop="currentPoint"
                    align="center"
                    label="当前积分"
                    max-width="130"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.currentPoint ? scope.row.currentPoint : 0}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="getTotalPoint"
                    align="center"
                    label="累计积分"
                    max-width="130"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.getTotalPoint ? scope.row.getTotalPoint : 0}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="exchangeCount"
                    align="center"
                    label="兑换次数"
                    max-width="130"
                ></el-table-column>
                <el-table-column
                    prop="exchangeTotalPoint"
                    align="center"
                    label="兑换积分"
                    max-width="130"
                ></el-table-column>
                <el-table-column
                    prop="latestAlterPointTime"
                    align="center"
                    label="最近修改积分时间"
                    min-width="220"
                >
                    <template slot-scope="scope">
                        <div class="point-time" v-if="scope.row.latestAlterPointTime">{{scope.row.latestAlterPointTime | timeFilter}}</div>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="latestAlterPoint"
                    width="170"
                >
                    <template slot="header">
                        修改积分
                        <a class="text-operate-btn" href="javascript: void(0);" @click="handleJumpModifyRecord">（修改记录）</a>
                    </template>
                    <template slot-scope="scope">
                        <span>{{scope.row.latestAlterPoint ? scope.row.latestAlterPoint : '-'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    align="center"
                    min-width="140"
                >
                    <template slot-scope="scope">
                        <span class="text-operate-btn" @click="handlePoint(scope.row)">修改积分</span>
                    </template>
                </el-table-column>
                <template slot="empty">
                    <div>
                        <span class="empty-img"></span>
                        <p class="empty-data">暂无数据</p>
                    </div>
                </template>
            </el-table>
        </div>
    </div>
</template>

<script>
import TeamService from '#/js/service/teamService.js';
import TeamMemberSelect from '#/component/common/team-member-select.vue';
import moment from 'moment';
export default {
    props: {
        tableData: Array
    },
    components: {
        TeamMemberSelect
    },
    data() {
        return {
            isFocusAdminDate: false,
            teamMemberPopoverVisible: false,
            selectedMembersText: '全部成员',
            params: {
                userIds: []
            },
            loading: false
        }
    },
    filters: {
        timeFilter(val) {
            return moment(val).format('YYYY.MM.DD HH:mm:DD');
        }
    },
    mounted() {
        this.loading = true;
        TeamService.getTeamMembers({
            teamId: '0',
            take: -1,
            isShowDimission: false
        }).then(res => {
            let userIds = [], list = res.list || [];
            list.forEach(item => {
                userIds.push(item.userId);
            })
            this.params.userIds = userIds;
            this.search();     
        })
    },
    methods: {
        search() {
            this.$emit('search', this.params);
        },
        // 成员选取
        employeeSelect(userIds, text) {
            this.params.userIds = userIds;
            this.selectedMembersText = text;
            this.search();
            this.teamMemberPopoverVisible = false;
        },
        handleSelectionChange(val) {
            this.$emit('selectionChange', val);
        },
        handlePoint(user) {
            this.$emit('singleHandlePoint', user);
        },
        handleJumpModifyRecord() {
            sessionStorage.removeItem('pointModifyRecordUserIds');
            localStorage.setItem('pointModifyRecordUserIds', JSON.stringify({
                userIds: this.params.userIds
            }));
            window.open('/Headhunting/MyCompany.html#/myPointModifyRecord', '_blank');
        }
    }
};
</script>

<style lang="scss" scope>
    .point-table {
        display: flex;
        flex-direction: column;
        height: 100%;
        &-top {
            margin: 12px 0;
            padding-left: 32px;
            height: 28px;
            line-height: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
                font-size: 18px;
                color: #4a4a4a;
            }
            .point-table-operation {
                display: flex;
                .employee-select-wrap {
                    position: relative;
                    margin-right: 20px;

                    .employee-select-text {
                        display: inline-block;
                        width: 116px;
                        height: 32px;
                        line-height: 32px;
                        background-color: #fff;
                        border: 1px solid #d3d3d3;
                        border-radius: 16px;
                        padding: 0 36px 0 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: top;
                    }

                    &:hover{
                        .employee-select-text{
                            background: #38BC9D;
                            border-color: #38BC9D;
                            color: #fff;
                        }

                    }
                    &.triangle-drop-up{
                        &::after{
                            border-bottom: 12px solid #fff;
                            margin-top: -3px;
                        }
                        .employee-select-text{
                            background: #38BC9D;
                            border-color: #38BC9D;
                            color: #fff;
                        }
                    }
                }
                .triangle-drop {
                    &::after {
                        position: absolute;
                        display: block;
                        content: '';
                        top: 50%;
                        transform: translateY(-50%);
                        right: 16px;
                        pointer-events: none;
                    }
                    &-down {
                        &::after {
                            border-top: 12px solid #ddd;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                        }
                        &:hover {
                            &::after {
                                border-top: 12px solid #fff;
                            }
                        }
                    }
                    &-up {
                        .team-select {
                            background: #38BC9D;
                            border-color: #38BC9D;
                            .team-select-text{
                                color: #fff;
                            }
                        }
                        &::after {
                            border-bottom: 12px solid #ddd;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                        }
                        &:hover {
                            &::after {
                                border-bottom: 12px solid #fff;
                            }
                        }
                    }
                }
            }
        }
        &-body {
            flex-grow: 1;
            height: calc(100% - 52px);
            letter-spacing: 0px;
            .el-table {
                background-color: transparent;
                height: 100% !important;
                overflow-y: auto;
                min-height: 244px;
                .el-table__body-wrapper {
                    height: calc(100% - 43px) !important;
                    background-color: #fff;
                    overflow-y: auto;
                }
                th {
                    padding: 0;
                    height: 42px;
                    line-height: 42px;
                    background-color: #E8E8E8;
                    border-color: #CCC;
                    color: #666;
                    .cell {
                        .el-checkbox {
                            display: none;
                        }
                    }
                    &:nth-of-type(1) {
                        border-right: none;
                    }
                }
                td {
                    .cell {
                        .el-checkbox__inner {
                            border-color: #999;
                        }
                        .el-checkbox__input.is-checked .el-checkbox__inner {
                            background-color: #fff;
                            border-color: $primary;
                            &::after {
                                border-color: $primary;
                            }
                        }
                    }
                }
                td:nth-of-type(1) {
                    border-right: none;
                    .cell {
                        padding-right: 10px;
                    }
                }
                .userName-column {
                    .cell {
                        padding-left: 54px;
                    }
                }
                .el-table__empty-block {
                    .el-table__empty-text {
                        line-height: inherit;
                    }
                    .empty-img {
                        display: inline-block;
                        background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                        width: 240px;
                        height: 228px;
                    }
                    .empty-data {
                        font-size: 16px;
                        line-height: 21px;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
.team-member-popover.point-team-member-popover {
    transform: translateX(0);
}
</style>