<template>
  <div class="management-container">
    <div class="management-body">
      <template v-if="pageType == 0">
        <prize-table ref="prizeTable" :tableData="tableData" @search="paramsChange"></prize-table>
      </template>
      <template v-if="pageType == 1">
        <settlement-table ref="settlementTable" :tableData="tableData" @search="paramsChange"></settlement-table>
      </template>
      <template v-if="pageType == 2">
        <point-table
          ref="pointTable"
          :tableData="tableData"
          @search="paramsChange"
          @selectionChange="handleSelectionChange"
          @singleHandlePoint="singleHandlePoint"
        ></point-table>
      </template>
    </div>
    <table-page-tab :filterIndex="pageType" :pageTabs="pageTabJson"></table-page-tab>
    <div class="work-table-footer">
      <div class="footer-left" v-if="pageType == 2">
        <div class="footer-select">
          <input hidden v-model="allSelect" type="checkbox" />
          <span class="all-select-checkbox" @click="handleAllSelect"></span>
          <span>全选</span>
          <span class="select-count">
            已选择
            <span class="color-orange">{{multipleSelection.length}}</span>
            位成员
          </span>
        </div>
        <el-button
          type="primary"
          class="point-btn"
          :disabled="multipleSelection.length == 0"
          @click="multipleHandlePoint"
        >修改积分</el-button>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.current"
        :page-sizes="[20, 30, 50]"
        :page-size="page.size"
        class="el-pagination-workTable"
        layout="total, sizes, prev, pager, next, slot"
        :total="page.total"
      >
        <span class="pagination-text">
          <span>
            前往
            <el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页
          </span>
          <span @click="handlePagerJump">跳转</span>
        </span>
      </el-pagination>
    </div>
    <point-dialog ref="pointDialog" @refresh-list="getSearchJson(false)"></point-dialog>
  </div>
</template>

<script>
import PrizeTable from "../component/prize-table.vue";
import SettlementTable from "../component/settlement-table.vue";
import PointTable from "../component/point-table.vue";
import TablePageTab from "#/component/workTable/table-page-tab.vue";
import PointDialog from "../component/point-dialog.vue";
import PointSettingService from '#/js/service/pointSettingService.js';
export default {
  props: {
    type: String
  },
  components: {
    PrizeTable,
    SettlementTable,
    PointTable,
    TablePageTab,
    PointDialog
  },
  computed: {
    pageType() {
      switch (this.type) {
        case "prize":
          return 0;
        case "settlement":
          return 1;
        case "point":
          return 2;
      }
    },
    allSelect() {
      return this.tableData.length > 0
        ? this.tableData.length == this.multipleSelection.length
        : false;
    },
    isPointSet() {
        return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('PointSet');
    },
  },
  data() {
    return {
      pageTabJson: [
        // {
        //   enTitle: "PointSet",
        //   cnTitle: "积分设置",
        //   path: "/pointSetting/prize",
        //   title: "奖品管理",
        //   tabIndex: 0,
        //   aliveComponent: "PointSettingPrize"
        // },
        // {
        //   enTitle: "PointSet",
        //   cnTitle: "积分设置",
        //   path: "/pointSetting/settlement",
        //   title: "结算管理",
        //   tabIndex: 1,
        //   aliveComponent: "PointSettingSettleMent"
        // },
        {
          enTitle: "PointSet",
          cnTitle: "积分设置",
          path: "/pointSetting/point",
          title: "积分管理",
          tabIndex: 2,
          aliveComponent: "PointSettingPoint"
        }
      ],
      page: {
        total: 0,
        current: 1,
        size: 20
      },
      pagerJump: 0,
      multipleSelection: [],
      params: {},
      tableData: []
    };
  },
  mounted() {
    // if(this.pageType === 0) {
    //   this.getSearchJson(false);
    // } else {
    //   if(this.pageType === 1) {
    //     this.$refs.settlementTable.loading = true;
    //   } else {
    //     this.$refs.pointTable.loading = true;
    //   }
    // }

    if(this.$store.state.user.userInfo.isAdministrator || this.isPointSet){
      if(this.pageType === 0) {
        this.getSearchJson(false);
      } else {
        if(this.pageType === 1) {
          this.$refs.settlementTable.loading = true;
        } else {
          this.$refs.pointTable.loading = true;
        }
      }
    } else {
      shortTips("权限不足，请联系管理员");
      let tabItems = JSON.parse(sessionStorage.getItem("tabItems"));
      let _index = tabItems.findIndex(
          (item) => item.path === "/pointSetting/prize"
      );
      tabItems.splice(_index, 1);
      sessionStorage.setItem("tabItems", JSON.stringify(tabItems));
      setTimeout(() => {
          location.href = "/Headhunting/#/";
      }, 2000);
    }
  },
  methods: {
    paramsChange(params) {
      Object.assign(this.params, params);
      this.getSearchJson(false);
    },
    getSearchJson(slient) {
      let searchJson = Object.assign({}, this.params);
      if (!slient) {
        this.page.current = 1;
      }
      if(this.pageType == 2) {
        searchJson.start = (this.page.current - 1) * this.page.size;
        searchJson.take = this.page.size;
      } else {
        searchJson.offset = (this.page.current - 1) * this.page.size;
        searchJson.pageSize = this.page.size;
      }
      switch(this.pageType) {
        case 0:
          this.getPrizeData(searchJson);break;
        case 1:
          this.getSettlementData(searchJson);break;
        case 2:
          this.getPointData(searchJson);break;
      }
    },
    getPrizeData(json) {
      this.$refs.prizeTable.loading = true;
      PointSettingService.getPrizeList(json)
      .then(res => {
        this.$refs.prizeTable.loading = false;
        this.tableData = res.data;
        this.page.total = res.count;
      }).catch(err => {
        this.$refs.prizeTable.loading = false;
        console.log(err);
      })
    },
    getSettlementData(json) {
      this.$refs.settlementTable.loading = true;
      PointSettingService.getSettleList(json)
      .then(res => {
        this.$refs.settlementTable.loading = false;
        this.tableData = res.data;
        this.page.total = res.count;
      }).catch(err => {
        this.$refs.settlementTable.loading = false;
        console.log(err);
      })
    },
    getPointData(json) {
      this.$refs.pointTable.loading = true;
      PointSettingService.getPointLlist(json)
      .then(res => {
        this.$refs.pointTable.loading = false;
        this.tableData = res.list;
        this.page.total = res.total;
      }).catch(err => {
        this.$refs.pointTable.loading = false;
        console.log(err);
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    singleHandlePoint(user) {
      this.$refs.pointDialog.show(false, [user]);
    },
    handleAllSelect() {
      this.$refs.pointTable.$refs.multipleTable.toggleAllSelection();
    },
    multipleHandlePoint() {
      let list = [];
      this.multipleSelection.forEach(item => {
        list.push({
          userName: item.userName,
          userId: item.userId
        });
      })
      this.$refs.pointDialog.show(true, list);
    },
    handleSizeChange(size) {
      this.page.size = size;
      this.getSearchJson(false);
    },
    handleCurrentChange(current) {
      this.page.current = current;
      this.getSearchJson(true);
    },
    handlePagerJump() {
      let currentPager = Number(this.pagerJump),
        currentPageTotal = Math.ceil(this.page.total / this.page.size);
      if (currentPager > 0 && currentPager <= currentPageTotal) {
        this.handleCurrentChange(currentPager);
      } else {
        this.pagerJump = currentPageTotal;
        this.handleCurrentChange(currentPageTotal);
      }
    }
  }
};
</script>

<style lang="scss" scope>
.management-container {
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    background: url("~@src/assets/images/home/home_bg.png") bottom center no-repeat;
    .management-body {
      flex-grow: 1;
      height: calc(100% - 88px);
    }
    .work-table-footer {
      margin-top: 10px;
    }
    .point-btn.is-disabled {
        background-color: #F8F8F8;
        border: 1px solid #DDDDDD;
        color: #999999;
        cursor: not-allowed;

        &:hover {
          background-color: #F8F8F8;
        }
    }
}
</style>