<template>
    <el-dialog
        class="point-dialog"
        :title="isMultiple ? '批量修改积分' : '修改积分'"
        :visible.sync="visible"
        width="480px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="handleClose"
        v-loading="loading"
    >
        <div class="point-title">
            <template v-if="isMultiple">
                你正在批量修改<span class="text-primary">{{nameList.join('、')}}</span>等<span class="text-orange">{{nameList.length}}</span>位成员的当前积分
            </template>
            <template v-else>
                你正在修改<span class="text-primary">{{nameList.join('、')}}</span>的当前积分
            </template>
        </div>
        <el-form :model="form" :rules="rules" ref="form" label-width="120px">
            <el-form-item label="增加/扣除积分" prop="point">
                <span class="point-icon" :class="this.isAdd ? 'el-icon-plus' : 'el-icon-minus'" @click="handleAddOrSub"></span>
                <el-input class="point-input" v-model="form.point"></el-input>分
            </el-form-item>
            <el-form-item label="增加/扣除原因" prop="remarks">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="请填写原因"
                    v-model="form.remarks"
                    maxlength="50"
                    show-word-limit
                ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import pointSettingService from "#/js/service/pointSettingService.js";
let _this = {};
export default {
    name: "PrizeDialog",
    data() {
        return {
            visible: false,
            loading: false,
            isMultiple: false,
            form: {
                point: "",
                remarks: ""
            },
            rules: {
                point: [{
                    required: true,
                    trigger: "blur",
                    validator: (rule, value, callback) => {
                        if(value === "") {
                            callback(new Error("请输入要增加/扣除的积分"));
                        } else if(!(/^\d+$/g.test(value) && parseInt(value) > 0 && parseInt(value) < 10000)) {
                            callback(new Error("增加/扣除的积分的范围是1~9999的整数"));
                        } else {
                            callback();
                        }
                    }
                }],
                remarks: [
                    { required: true, message: "请输入增加/扣除积分的原因", trigger: "blur" }
                ]
            },
            nameList: [],
            ids: [],
            userList: [],
            isAdd: true
        }
    },
    methods: {
        show(isMultiple, list) {
            this.visible = true;
            this.isMultiple = isMultiple;
            this.userList = list;
            list.forEach(item => {
                this.nameList.push(item.userName);
                this.ids.push(item.userId);
            })
        },
        handleClose() {
            this.visible = false;
            this.$refs.form.resetFields();
            this.nameList = [];
            this.ids = [];
            this.isAdd = true;
        },
        handleConfirm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    let data = Object.assign({}, this.form);
                    data.userIds = this.ids;
                    data.point = parseInt(data.point);
                    if(!this.isAdd) {
                        data.point = -data.point;
                    }
                pointSettingService.alterPoint(data).then(res => {
                        this.loading = false;
                        let data = res.data.errorData;
                        if(data.length > 0) {
                            let errorMsg = [];
                            data.forEach(item => {
                                for(let i = 0; i < this.userList.length; i++) {
                                    if(item.id == this.userList[i].userId) {
                                        errorMsg.push('成员' + this.userList[i].userName + item.error.description);
                                    }
                                }
                            })
                            shortTips(errorMsg.join('<br/>'));
                        } else {
                            shortTips("修改成功");
                        }
                        this.handleClose();
                        this.$emit("refresh-list");
                    }).catch(err => {
                        this.loading = false;
                    });
                } else {
                    return false;
                }
            });
        },
        handleAddOrSub() {
            this.isAdd = !this.isAdd;
        }
    }
};
</script>

<style lang="scss" scoped>
.point-dialog {
    .text-primary {
        color: $primary;
    }
    .text-orange {
        color: #f9ca73;
    }
    .el-form-item__label {
        padding-right: 15px;
    }
    .point-icon {
        font-size: 16px;
        border: 1px solid #ccc;
        color: #000;
        cursor: pointer;
        &.el-icon-plus {
            color: #999;
        }
    }
    .point-input {
        width: 80px;
        margin: 0 10px;
    }
    .point-title {
        color: #444;
        line-height: 20px;
        margin-bottom: 20px;
    }
}
</style>